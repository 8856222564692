.Widgets--Wrap {
  --padding: 1rem;
  display: flex;
  flex-direction: column;
  z-index: 2;
  height: 100%;
  padding: var(--padding);
  background-color: var(--color-light-grey);
  overflow: auto;
}

.Widgets--Wrap > * + * {
  margin-top: var(--padding);
}

.Widgets--Box {
  border: 1px solid var(--color-mid-grey);
  background-color: white;
  border-radius: 5px;
  position: relative;
  padding: var(--padding);
  width: 100%;
}

.Widgets--Box--Inner {
  overflow: hidden;
  width: 100%;
  padding-bottom: 10px;
}

.Widgets--Box--CloseButton {
  position: absolute;
  cursor: pointer;
  top: 12px;
  right: 15px;
  width: 20px;
  height: 20px;
  opacity: 0.8;
}

.Widgets--Box--CloseButton:hover,
.Widgets--Box--CloseButton:focus {
  opacity: 1;
  transform: scale(1.1);
}

.Widgets--Box--Column--Title {
  font-size: 15px;
  margin-top: 40px;
  font-weight: 500;
  letter-spacing: 0.1em;
}

.Widgets--Box--Column + .Widgets--Box--Column {
  margin-left: 20px;
}

.Widgets--Title {
  position: absolute;
  font-weight: 300;
  font-size: 30px;
  margin: 0 0 20px 0;
}

.Widgets--Description {
  font-size: 17px;
  font-weight: 300;
  line-height: 30px;
  margin: 0;
  margin-top: 60px;
  padding-right: 20px;
  position: relative;
}

.main {
  height: 100vh;
  width: 100%;
}

.ReactSplitGutterHorizontal {
  padding: 0 2px;
  height: 100%;
  width: 12px;
  background: var(--color-mid-grey);

  border-width: 0;

  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReactSplitGutterHorizontal:hover {
  cursor: col-resize;
}

.ReactSplitDraggerHorizontal {
  height: 24px;
  width: 4px;
  background: var(--color-light-grey);
  border-radius: 2px;
}

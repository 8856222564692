.MapLegend {
  min-width: 250px;
}

.MapLegend--Title {
  margin: 0;
  margin-bottom: 15px;
}

.MapLegend--Unit {
  font-weight: 500;
}

.MapLegend--Display {
  --colourBoxSize: 25px;
  --colourBoxMargin: 10px;
  /* limit width to 2x9 rows */
  max-width: calc((var(--colourBoxSize) + var(--colourBoxMargin)) * 9);
}

.MapLegend--ColourStop {
  display: inline-block;
}

.MapLegend--ColourBox {
  height: var(--colourBoxSize);
  width: var(--colourBoxSize);
  border: 1px solid black;
  border-radius: 2px;
  margin-right: var(--colourBoxMargin);
  margin-bottom: var(--colourBoxMargin);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.MapLegend--Value {
  font-weight: 600;
}

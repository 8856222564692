:root {
  /* Colours */
  --blue: #0070f3;
  --green: #50e597;
  --red: #ff3d3d;
  --orange: #ffa500;
  --cyan: #00c3ff;
  --white: #ffffff;
  --off-white: #cacaca;
  --light-grey: #f0f0f0;
  --mid-grey: #c5c5c5;
  --black: #181818;
  --light-black: #282c34;
  --dark-grey: #494e57;

  --color-highlight: var(--blue);
  --color-highlight-b: var(--red);
  --color-highlight-c: var(--orange);
  --color-light-grey: var(--light-grey);
  --color-mid-grey: var(--mid-grey);
  --color-dark-grey: var(--dark-grey);
  --color-background: var(--white);
  --color-code: var(--light-black);

  /* Typography */
  /* --font-code: 'Fira Code', monospace; */
  --font-system: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  --font-mono: 'SF Mono', 'Monaco', 'Inconsolata', 'Fira Mono',
    'Droid Sans Mono', 'Source Code Pro', monospace;
}

body {
  margin: 0;
  font-family: var(--font-system);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.85);
}

code {
  font-family: var(--font-mono);
}

pre {
  background: whitesmoke;
  padding: 15px;
}

* > * {
  box-sizing: border-box;
}

strong {
  font-weight: 600;
}

abbr[title] {
  border-bottom: none;
  cursor: inherit;
  text-decoration: none;
  border-bottom: 1px dotted currentColor;
}

a {
  color: inherit;
  text-decoration: underline;
}

.content [id] {
  scroll-margin-top: 25px;
}

.button {
  appearance: none;
  display: inline-block;
  border: none;
  padding: 0.5em 1em;
  margin: 0;
  text-decoration: none;
  color: inherit;
  font-family: inherit;
  border-radius: 3px;
  background: whitesmoke;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  transition: background 150ms ease-in-out, transform 150ms ease;
}

.button:hover,
.button:focus {
  background: #eee;
}

.button:active {
  transform: scale(0.97);
}

.checkbox input[type='checkbox'],
.radio input[type='radio'] {
  margin-right: 0.3em;
}

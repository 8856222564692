.Menu--MenuButton {
  background: white;
  cursor: pointer;
  border-radius: 50%;
  padding: 0px;
  width: 40px;
  height: 40px;
  stroke-width: 1px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.1s ease-in-out;
}

.Menu--MenuButton:hover,
.Menu--MenuButton:focus {
  transform: scale(1.1);
  stroke-width: 1.5px;
}

.Menu--MenuButtonWrap {
  background-color: none;
  display: flex;
  align-items: center;
  border-radius: 30px 3px 3px 30px;
  margin-right: auto;
}

.Menu--HelpText {
  display: none;
  margin: 0 30px 0 20px;
  font-weight: 500;
  font-size: 0.9em;
  width: auto;
  white-space: nowrap;
}

.Menu--MenuButtonWrap[data-hashelptext='true'] {
  background-color: white;
}

.Menu--MenuButtonWrap[data-hashelptext='true'] .Menu--HelpText {
  display: block;
}

.spinner {
  --offset: 187;
  --offset-small: 55;
  --duration: 1.4s;
  animation: rotator var(--duration) linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: var(--offset);
  stroke-dashoffset: 0;
  transform-origin: center;
  stroke: #212121;
  animation: dash var(--duration) ease-in-out infinite,
    colors calc(var(--duration) * 4) ease-in-out infinite;
}

.pathSmall {
  stroke-dasharray: var(--offset-small);
  stroke-dashoffset: 0;
  transform-origin: center;
  stroke: #212121;
  animation: dashSmall var(--duration) ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dashoffset: var(--offset);
  }
  50% {
    stroke-dashoffset: calc(var(--offset)/4);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: var(--offset);
    transform: rotate(450deg);
  }
}

@keyframes dashSmall {
  0% {
    stroke-dashoffset: var(--offset-small);
  }
  50% {
    stroke-dashoffset: calc(var(--offset-small)/4);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: var(--offset-small);
    transform: rotate(450deg);
  }
}

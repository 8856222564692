.Popup--CloseButton {
  width: 1em;
  height: 1em;
  cursor: pointer;
}

.Popup--CloseButton {
  position: absolute;
  cursor: pointer;
  top: 12px;
  right: 15px;
  width: 20px;
  height: 20px;
  opacity: 0.8;
}

.Popup--CloseButton:hover,
.Popup--CloseButton:focus {
  opacity: 1;
  transform: scale(1.1);
}

.Popup--Modal {
  right: auto;
  bottom: auto;
  position: relative;
  background: white;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
  width: 800px;
  max-width: 95%;
  border-radius: 10px;
  border: 1px solid #ddd;
  margin: auto;
  overflow: initial;
  animation: Popup--modal 0.3s;
}

.Popup--Modal:focus {
  outline: none;
}

.Popup--Modal--Inner {
  overflow: auto;
  max-height: 95vh;
  position: relative;
  padding: 30px 30px 40px;
}

.Popup--Title {
  font-weight: 500;
  font-size: 25px;
  margin: 0 0 20px 0;
}

.Popup--Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(21, 21, 21, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

@keyframes Popup--modal {
  from {
    opacity: 0;
    transform: translate(0, 5px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0px);
  }
}
